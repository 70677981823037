<script setup>
import Card from '@/Components/Shared/Cards/Card.vue';
import { useShare, useWindowSize } from '@vueuse/core';
import { Link, usePage } from '@inertiajs/vue3';
import route from '@/Helpers/route';
import RestaurantAddressDialog from '@/Components/Shared/Restaurant/RestaurantAddressDialog.vue';

const page = usePage();

const props = defineProps({
    restaurant: Object,
});

const { width } = useWindowSize();

const { share, isSupported } = useShare();

const startShare = () => {
    share({
        title: props.restaurant.name,
        url: location.href,
    });
};
</script>

<template>
    <Card>
        <Link :href="route(`${page.props.routing_group}.menu`, { [page.props.routing_key]: page.props.routing_target })" class="hidden xl:block w-full">
            <img :src="restaurant.links.wide_banner" :srcset="restaurant.links.responsive_wide_banner" :alt="restaurant.name" class="block w-full rounded-t-xl mx-auto" v-if="restaurant.links.wide_banner" :dusk="`restaurant-${restaurant.id}`">
        </Link>

        <Link :href="route(`${page.props.routing_group}.menu`, { [page.props.routing_key]: page.props.routing_target })" class="block md:hidden w-full">
            <img :src="restaurant.links.rectangle_banner" :srcset="restaurant.links.responsive_rectangle_banner" :alt="`Banner ${restaurant.name}`" class="block w-full aspect-video rounded-t-xl object-cover mx-auto">
        </Link>

        <div class="flex flex-col md:flex-row gap-x-12 p-4">
            <Link :href="route(`${page.props.routing_group}.menu`, { [page.props.routing_key]: page.props.routing_target })" class="hidden md:block my-auto">
                <img :src="restaurant.links.logo" :srcset="restaurant.links.responsive_logo" :alt="`Logo ${restaurant.name}`" class="block max-w-sm max-h-48 w-full h-auto md:mx-auto">
            </Link>

            <div class="my-auto">
                <h1 class="font-bold text-[28px] text-gray-700 leading-tight mb-4">{{ restaurant.name }}</h1>

                <div class="grid grid-cols-1 md:grid-cols-3 gap-x-24 gap-y-2">
                    <div class="flex flex-col">
                        <a :href="`mailto:${restaurant.email}`" class="inline-flex flex-row items-center gap-2 font-medium text-gray-700">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" class="w-4 h-4 fill-gray-700">
                                <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/>
                            </svg>

                            <span class="font-medium text-gray-700">{{ restaurant.email }}</span>
                        </a>
                        <a :href="`tel:${restaurant.phone}`" class="inline-flex flex-row items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" class="w-4 h-4 fill-gray-700">
                                <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/>
                            </svg>

                            <span class="font-medium text-gray-700">{{ restaurant.phone }}</span>
                        </a>
                        <div class="inline-flex flex-row items-center gap-2" v-if="restaurant.reviews_count > 0">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="w-4 h-4 fill-yellow-400">
                                <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                            </svg>

                            <span class="font-medium text-gray-700">{{ restaurant.stars ?? '0.0' }}</span>

                            <Link :href="route(`${page.props.routing_group}.reviews`, {[page.props.routing_key]: page.props.routing_target})" class="font-medium text-blue-500">
                                {{ restaurant.reviews_count }} beoordeling(en)
                            </Link>
                        </div>
                    </div>

                    <div class="flex flex-col">
                        <RestaurantAddressDialog :restaurant="restaurant" />
                    </div>

                    <div class="flex flex-col">
                        <Link :href="route(`${page.props.routing_group}.info`, {[page.props.routing_key]: page.props.routing_target})" class="font-medium text-blue-500">
                            Meer informatie
                        </Link>

                        <Link :href="route(`${page.props.routing_group}.contact.create`, {[page.props.routing_key]: page.props.routing_target})" class="font-medium text-blue-500">
                            Contact formulier
                        </Link>

                        <Link :href="route(`${page.props.routing_group}.news`, {[page.props.routing_key]: page.props.routing_target})" class="font-medium text-blue-500" v-if="restaurant.news_count > 0">
                            Nieuws
                        </Link>

                        <Link :href="route(`${page.props.routing_group}.vacancies`, {[page.props.routing_key]: page.props.routing_target})" class="font-medium text-blue-500" v-if="restaurant.vacancies_count > 0">
                            {{ restaurant.vacancies_count }} vacatures
                        </Link>

                        <a href="" v-if="isSupported && width < 1024" @click.prevent="startShare" class="font-medium text-sky-500">Delen</a>

                        <div class="flex flex-row gap-2">
                            <a :href="link.url" target="_blank" class="font-medium text-sky-500" v-for="link in restaurant.custom_links" :key="link.id" rel="nofollow">
                                <img src="/images/social-media/discord-square.svg" :alt="`${restaurant.name} Discord`" class="w-8 h-8" v-if="link.url.includes('discord')">
                                <img src="/images/social-media/facebook-square.svg" :alt="`${restaurant.name} Facebook`" class="w-8 h-8" v-else-if="link.url.includes('facebook')">
                                <img src="/images/social-media/instagram-square.svg" :alt="`${restaurant.name} Instagram`" class="w-8 h-8" v-else-if="link.url.includes('instagram')">
                                <img src="/images/social-media/kvk-square.svg" :alt="`${restaurant.name} KVK`" class="w-8 h-8" v-else-if="link.url.includes('kvk')">
                                <img src="/images/social-media/linkedin-square.svg" :alt="`${restaurant.name} LinkedIn`" class="w-8 h-8" v-else-if="link.url.includes('linkedin')">
                                <img src="/images/social-media/medium-square.svg" :alt="`${restaurant.name} Medium`" class="w-8 h-8" v-else-if="link.url.includes('medium')">
                                <img src="/images/social-media/pinterest-square.svg" :alt="`${restaurant.name} Pinterest`" class="w-8 h-8" v-else-if="link.url.includes('pinterest')">
                                <img src="/images/social-media/reddit-square.svg" :alt="`${restaurant.name} Reddit`" class="w-8 h-8" v-else-if="link.url.includes('reddit')">
                                <img src="/images/social-media/snapchat-square.svg" :alt="`${restaurant.name} Snapchat`" class="w-8 h-8" v-else-if="link.url.includes('snapchat')">
                                <img src="/images/social-media/soundcloud-square.svg" :alt="`${restaurant.name} SoundCloud`" class="w-8 h-8" v-else-if="link.url.includes('soundcloud')">
                                <img src="/images/social-media/telegram-square.svg" :alt="`${restaurant.name} Telegram`" class="w-8 h-8" v-else-if="link.url.includes('telegram')">
                                <img src="/images/social-media/the-fork-square.svg" :alt="`${restaurant.name} TheFork`" class="w-8 h-8" v-else-if="link.url.includes('the-fork')">
                                <img src="/images/social-media/tiktok-square.svg" :alt="`${restaurant.name} TikTok`" class="w-8 h-8" v-else-if="link.url.includes('tiktok')">
                                <img src="/images/social-media/tripadvisor-square.svg" :alt="`${restaurant.name} Tripadvisor`" class="w-8 h-8" v-else-if="link.url.includes('tripadvisor')">
                                <img src="/images/social-media/tumblr-square.svg" :alt="`${restaurant.name} Tumblr`" class="w-8 h-8" v-else-if="link.url.includes('tumblr')">
                                <img src="/images/social-media/twitch-square.svg" :alt="`${restaurant.name} Twitch`" class="w-8 h-8" v-else-if="link.url.includes('twitch')">
                                <img src="/images/social-media/twitter-square.svg" :alt="`${restaurant.name} Twitter`" class="w-8 h-8" v-else-if="link.url.includes('twitter')">
                                <img src="/images/social-media/whatsapp-square.svg" :alt="`${restaurant.name} WhatsApp`" class="w-8 h-8" v-else-if="link.url.includes('whatsapp')">
                                <img src="/images/social-media/youtube-square.svg" :alt="`${restaurant.name} YouTube`" class="w-8 h-8" v-else-if="link.url.includes('youtube')">
                                <template v-else>{{ link.text }}</template>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Card>
</template>
