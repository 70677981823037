<script setup>
import Dialog from '@/Components/Shared/Dialogs/Dialog.vue';
import { ref } from 'vue';

defineProps({
    restaurant: Object,
});

const isDialogOpen = ref(false);

const setDialogIsOpen = (value) => {
    isDialogOpen.value = value;
};
</script>

<template>
    <a href="#" class="not-italic font-medium text-blue-500" @click.prevent="setDialogIsOpen(true)">
        {{ restaurant.address.street }} {{ restaurant.address.number }} {{ restaurant.address.extra }}
        <br>
        {{ restaurant.address.city }} {{ restaurant.address.zipcode }}
        <br>
        {{ restaurant.address.country }}
    </a>

    <Dialog class="max-w-xl!" :is-open="isDialogOpen" :set-is-open="setDialogIsOpen">
        <div class="p-3">
            <h3 class="font-semibold text-lg xl:text-xl text-gray-700 leading-tight">Google Maps - {{ restaurant.address.street }} {{ restaurant.address.number }} {{ restaurant.address.extra }}</h3>
        </div>
        <iframe class="aspect-square w-full rounded-sm" :src="restaurant.address.google_maps_embed_url"></iframe>
    </Dialog>
</template>
